import { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import RouteForm from '../components/journey-planner/route-form/route-form'
import JourneyRouteListArea from '../components/JourneyRouteListArea'
import NavigationPanel from '../components/navigation/navigation-panel'
import Tabs from '../components/navigation/tabs'
import RecentJourneysList from '../components/RecentJourneysList'
import { useReturnMapToDefaultView } from '../hooks/use-return-map-to-default-view'
import useTitle from '../hooks/use-title'
import { useGoogleLoadState } from '../stores/GoogleMapState'
import {
  updatePlacesFromQuery,
  useActiveSearchState,
  useInvalidSearchState,
} from '../stores/journey-planner/JourneySearchState'
import { resetFiltersForView } from '../stores/MapFiltersState'
import { useActiveTabState, useTabPropsState } from '../stores/TabNavigationState'
import { defaultTabProps } from '../types/JourneyPlannerTypes'
import CloneNode from '../components/clone-inner'

export default function JourneyPlanner() {
  const googleLoadState = useGoogleLoadState()
  const hasInvalidSearch = useInvalidSearchState()
  const hasActiveSearch = useActiveSearchState()
  const activeTabState = useActiveTabState()
  const tabPropsState = useTabPropsState()
  const search = useLocation().search
  useTitle(`Journey planner | NZTA Journey Planner`)
  useReturnMapToDefaultView()

  // In the journey planner, when the a & b search query changes in the URL
  // we load the place details (lat/lng, title) from those place ids
  useEffect(() => {
    const query = new URLSearchParams(search)
    if (googleLoadState.get()) {
      updatePlacesFromQuery(query)
    }
  }, [googleLoadState, search])

  // On component mount reset the tabs state and set page title
  useEffect(() => {
    resetFiltersForView('journey-planner')
    tabPropsState.set(defaultTabProps)
    activeTabState.set(tabPropsState.get().tabs[0].tabType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // We could alternatively not use a router for all routes beneath /journey-planner/
  // Load some initial state from the URL (if there's a query etc)
  // and after that just write updates to the URL based on state changes
  // would save having to do a Places lookup after every change in the autocomplete
  // and simplifies these components to not have to watch URL query changes, only real state

  return (
    <NavigationPanel>
      <RouteForm />
      {/* If there are no errors, and an active search, show tabs on mobile. */}
      {!hasInvalidSearch.get() && hasActiveSearch.get() && (
        <Tabs
          activeTab={activeTabState.get()}
          menuName={tabPropsState.get().menuName}
          tabs={tabPropsState.get().tabs}
          hideOnDesktop
        ></Tabs>
      )}
      <Switch>
        <Route path={`/journey-planner/commute/:index(\\d+)`} component={JourneyRouteListArea} />
        <Route path='/journey-planner' component={RecentJourneysList} />
        <Redirect to={`/journey-planner/${search}`} />
      </Switch>
      <CloneNode
        selector='#page-help__journey-planner-disclaimer'
        className='c-navigation-panel__section c-navigation-panel__section--border-top'
      />
    </NavigationPanel>
  )
}
