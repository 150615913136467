import { useEffect, useRef, useState } from 'react'

export default function CloneNode({ selector, className }: { selector: string; className?: string }) {
  const ref = useRef<HTMLDivElement>(null)
  const [contentState, setContentState] = useState<string | null>(null)

  useEffect(() => {
    if (ref.current) {
      const element: HTMLDivElement | null = document.querySelector(selector)
      ref.current.innerHTML = element?.innerHTML ?? ''

      setContentState((element?.innerHTML ?? '').trim())
    }
  }, [ref, selector])

  return <div ref={ref} className={contentState ? className : ''}></div>
}
