import { useEffect, useMemo } from 'react'
import { createState, useState } from '@hookstate/core'
import { HolidayJourneyFeature, HolidayJourneyFeatureCollection } from '../../types/features'
import { GeojsonFeature, Region } from '../../types/JourneyPlannerTypes'
import useRegions from '../../hooks/use-regions'
import { useParams } from 'react-router-dom'

type RegionJourneyMap = {
  [region: string]: {
    region: GeojsonFeature<Region>
    journeys: HolidayJourneyFeature[]
  }
}

let loadStarted = false
const useLoadIfNeeded = () => {
  useEffect(() => {
    // TODO initiate a refresh every 5 minutes?
    if (!hotspotState.get() && !loadStarted) {
      loadData()
    }
  }, [])
}

const hotspotState = createState(0)
let hotspotDataAll: HolidayJourneyFeature[] = []
let publishedHotspotData: HolidayJourneyFeature[] = []
let specificHotspotData: HolidayJourneyFeature[] = []
export const useJourneysState = () => {
  useLoadIfNeeded()
  return useState(hotspotState)
}
export const getHolidayJourneys = () => {
  return publishedHotspotData
}

export const getRegionalHolidayJourneys = () => {
  if (specificHotspotData.length >= 1) {
    return specificHotspotData
  }
  return []
}

// Get all published journeys to display on map
export const useSpecificHolidayJourneys = () => {
  const { holiday } = useParams<{ holiday: string }>()

  specificHotspotData = hotspotDataAll.filter((i) => i.properties.holidaySlug === holiday)

  let holidayName: any = new Set()
  specificHotspotData.forEach((i) => {
    holidayName.add(i.properties.holidayName)
  })
  holidayName = [...holidayName]

  return holidayName
}

async function loadData() {
  loadStarted = true
  const response = await fetch('/api/hotspots', { headers: { Accept: 'application/json' } })
  const data: { [holiday: string]: HolidayJourneyFeatureCollection } = await response.json()
  loadStarted = false
  if (data) {
    hotspotDataAll = Object.values(data).flatMap((collection: HolidayJourneyFeatureCollection) => collection.features)
    publishedHotspotData = hotspotDataAll.filter((i) => i.properties.publishedHoliday === true)
    hotspotState.set(1)
  }
}

export const useJourneysByRegion = (): [boolean, RegionJourneyMap] => {
  const { regions } = useRegions()
  const journeysLoaded = useJourneysState()

  const journeys = useMemo(() => {
    if (journeysLoaded.get()) {
      /** Return specific holiday data only (specific to URL slug).  
        * CMS users should be able to view any holiday data, even in draft state, 
        * yet this will be limited for non CMS admin users at the API request level.  
        */
      return specificHotspotData
    }
    return []
    // eslint-disable-next-line
  }, [journeysLoaded.value])

  // reduce regions to create `regionsWithJourneys` because regions are already sorted in correct order.
  const regionsWithJourneys: RegionJourneyMap = useMemo(() => {
    return regions.reduce((acc: RegionJourneyMap, val: GeojsonFeature<Region>) => {
      const regionTitle = val.properties.name
      if (regionTitle && val != null) {
        const journeysInRegion = journeys.filter((j) => j.properties.RegionTitle === regionTitle)
        if (journeysInRegion.length > 0) {
          acc[regionTitle] = {
            journeys: journeys.filter((j) => j.properties.RegionTitle === regionTitle),
            region: val,
          }
        }
      }
      return acc
    }, {})
    // eslint-disable-next-line
  }, [journeys, regions])

  return [!!journeysLoaded.get(), regionsWithJourneys]
}
